import React, { useEffect, useRef, useState } from 'react';

export default function CalendlyEmbeds({ calendlyLink }) {
  const calendlyRef = useRef(null);  // Ref to the div that will hold the Calendly embed
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    // Only load the script if it's not already loaded
    if (!window.Calendly) {
      const script = document.createElement("script");
      script.src = "https://assets.calendly.com/assets/external/widget.js";
      script.async = true;
      script.onload = () => setScriptLoaded(true);
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    } else {
      setScriptLoaded(true);  // Calendly is already loaded
    }
  }, []);

  useEffect(() => {
    if (scriptLoaded && calendlyLink && calendlyRef.current) {
      // Initialize Calendly widget once the script is loaded and the ref container is available
      window.Calendly.initInlineWidget({
        url: calendlyLink,
        parentElement: calendlyRef.current,
        prefill: {},
        utm: {}
      });
    }
  }, [scriptLoaded, calendlyLink]);

  return (
    <div 
      ref={calendlyRef} 
      style={{ minWidth: '320px', height: '350px', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
      {/* Calendly widget will be injected here */}
    </div>
  );
}
